import { default as authaEHLP9PyrMMeta } from "/opt/app/src/pages/auth/auth.vue?macro=true";
import { default as enteryu1oh4eDcNMeta } from "/opt/app/src/pages/auth/enter.vue?macro=true";
import { default as key109cnST3KKMeta } from "/opt/app/src/pages/auth/key.vue?macro=true";
import { default as lockIo2Cr9wSc8Meta } from "/opt/app/src/pages/auth/lock.vue?macro=true";
import { default as loginToHyY2FnJtMeta } from "/opt/app/src/pages/auth/login.vue?macro=true";
import { default as browserhVYKt3A0e1Meta } from "/opt/app/src/pages/browser.vue?macro=true";
import { default as camera9JvQKAKKs2Meta } from "/opt/app/src/pages/camera.vue?macro=true";
import { default as changelogjlpGpqQA7FMeta } from "/opt/app/src/pages/changelog.vue?macro=true";
import { default as convertKoKRK79knSMeta } from "/opt/app/src/pages/convert.vue?macro=true";
import { default as authBr3TLtuz4NMeta } from "/opt/app/src/pages/dev/auth.vue?macro=true";
import { default as index4fTyrb5WhlMeta } from "/opt/app/src/pages/dev/index.vue?macro=true";
import { default as mocksL2NVMCHwkbMeta } from "/opt/app/src/pages/dev/mocks.vue?macro=true";
import { default as welcomeEfooWPKUQlMeta } from "/opt/app/src/pages/dev/old/welcome.vue?macro=true";
import { default as sentryl1RcMRkHXZMeta } from "/opt/app/src/pages/dev/sentry.vue?macro=true";
import { default as _91txId_930pHgbbLN8zMeta } from "/opt/app/src/pages/history/[txId].vue?macro=true";
import { default as indexRdEEsFMe8fMeta } from "/opt/app/src/pages/history/index.vue?macro=true";
import { default as homeT0Bky5KtwPMeta } from "/opt/app/src/pages/home.vue?macro=true";
import { default as indexNoged4G8MdMeta } from "/opt/app/src/pages/index.vue?macro=true";
import { default as offlinest3eMOEsqxMeta } from "/opt/app/src/pages/offline.vue?macro=true";
import { default as indexUIePd4vh2YMeta } from "/opt/app/src/pages/pay/index.vue?macro=true";
import { default as statusSgJp2XVuO9Meta } from "/opt/app/src/pages/pay/status.vue?macro=true";
import { default as payp2ZvU1MBw1Meta } from "/opt/app/src/pages/pay.vue?macro=true";
import { default as indexjiaaQpn5vbMeta } from "/opt/app/src/pages/profile/index.vue?macro=true";
import { default as referrals3pXcD9udnwMeta } from "/opt/app/src/pages/profile/referrals.vue?macro=true";
import { default as profilepeTDblfVgIMeta } from "/opt/app/src/pages/profile.vue?macro=true";
import { default as to_45install1w6It3Ng2bMeta } from "/opt/app/src/pages/to-install.vue?macro=true";
import { default as indexczsb4xuSTcMeta } from "/opt/app/src/pages/topup/index.vue?macro=true";
import { default as qrcVSzMogr51Meta } from "/opt/app/src/pages/topup/qr.vue?macro=true";
import { default as topup4xQffYp5IAMeta } from "/opt/app/src/pages/topup.vue?macro=true";
import { default as addresslWaVnsbq7WMeta } from "/opt/app/src/pages/transfer/address.vue?macro=true";
import { default as checkWgn4J7jE0AMeta } from "/opt/app/src/pages/transfer/check.vue?macro=true";
import { default as indexkorPHnzdtMMeta } from "/opt/app/src/pages/transfer/index.vue?macro=true";
import { default as scanqtCisM4DogMeta } from "/opt/app/src/pages/transfer/scan.vue?macro=true";
import { default as statusTSODW4ivMHMeta } from "/opt/app/src/pages/transfer/status.vue?macro=true";
import { default as transfer2ERQimqR8EMeta } from "/opt/app/src/pages/transfer.vue?macro=true";
import { default as welcomev5s5EwULrqMeta } from "/opt/app/src/pages/welcome.vue?macro=true";
export default [
  {
    name: "auth-auth",
    path: "/auth/auth",
    meta: authaEHLP9PyrMMeta || {},
    component: () => import("/opt/app/src/pages/auth/auth.vue")
  },
  {
    name: "auth-enter",
    path: "/auth/enter",
    meta: enteryu1oh4eDcNMeta || {},
    component: () => import("/opt/app/src/pages/auth/enter.vue")
  },
  {
    name: "auth-key",
    path: "/auth/key",
    component: () => import("/opt/app/src/pages/auth/key.vue")
  },
  {
    name: "auth-lock",
    path: "/auth/lock",
    component: () => import("/opt/app/src/pages/auth/lock.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginToHyY2FnJtMeta || {},
    component: () => import("/opt/app/src/pages/auth/login.vue")
  },
  {
    name: "browser",
    path: "/browser",
    meta: browserhVYKt3A0e1Meta || {},
    component: () => import("/opt/app/src/pages/browser.vue")
  },
  {
    name: "camera",
    path: "/camera",
    meta: camera9JvQKAKKs2Meta || {},
    component: () => import("/opt/app/src/pages/camera.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogjlpGpqQA7FMeta || {},
    component: () => import("/opt/app/src/pages/changelog.vue")
  },
  {
    name: "convert",
    path: "/convert",
    meta: convertKoKRK79knSMeta || {},
    component: () => import("/opt/app/src/pages/convert.vue")
  },
  {
    name: "dev-auth",
    path: "/dev/auth",
    component: () => import("/opt/app/src/pages/dev/auth.vue")
  },
  {
    name: "dev",
    path: "/dev",
    component: () => import("/opt/app/src/pages/dev/index.vue")
  },
  {
    name: "dev-mocks",
    path: "/dev/mocks",
    meta: mocksL2NVMCHwkbMeta || {},
    component: () => import("/opt/app/src/pages/dev/mocks.vue")
  },
  {
    name: "dev-old-welcome",
    path: "/dev/old/welcome",
    meta: welcomeEfooWPKUQlMeta || {},
    component: () => import("/opt/app/src/pages/dev/old/welcome.vue")
  },
  {
    name: "dev-sentry",
    path: "/dev/sentry",
    meta: sentryl1RcMRkHXZMeta || {},
    component: () => import("/opt/app/src/pages/dev/sentry.vue")
  },
  {
    name: "history-txId",
    path: "/history/:txId()",
    meta: _91txId_930pHgbbLN8zMeta || {},
    component: () => import("/opt/app/src/pages/history/[txId].vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexRdEEsFMe8fMeta || {},
    component: () => import("/opt/app/src/pages/history/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeT0Bky5KtwPMeta || {},
    component: () => import("/opt/app/src/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexNoged4G8MdMeta || {},
    component: () => import("/opt/app/src/pages/index.vue")
  },
  {
    name: "offline",
    path: "/offline",
    meta: offlinest3eMOEsqxMeta || {},
    component: () => import("/opt/app/src/pages/offline.vue")
  },
  {
    name: payp2ZvU1MBw1Meta?.name,
    path: "/pay",
    component: () => import("/opt/app/src/pages/pay.vue"),
    children: [
  {
    name: "pay",
    path: "",
    component: () => import("/opt/app/src/pages/pay/index.vue")
  },
  {
    name: "pay-status",
    path: "status",
    component: () => import("/opt/app/src/pages/pay/status.vue")
  }
]
  },
  {
    name: profilepeTDblfVgIMeta?.name,
    path: "/profile",
    component: () => import("/opt/app/src/pages/profile.vue"),
    children: [
  {
    name: "profile",
    path: "",
    meta: indexjiaaQpn5vbMeta || {},
    component: () => import("/opt/app/src/pages/profile/index.vue")
  },
  {
    name: "profile-referrals",
    path: "referrals",
    meta: referrals3pXcD9udnwMeta || {},
    component: () => import("/opt/app/src/pages/profile/referrals.vue")
  }
]
  },
  {
    name: "to-install",
    path: "/to-install",
    meta: to_45install1w6It3Ng2bMeta || {},
    component: () => import("/opt/app/src/pages/to-install.vue")
  },
  {
    name: topup4xQffYp5IAMeta?.name,
    path: "/topup",
    component: () => import("/opt/app/src/pages/topup.vue"),
    children: [
  {
    name: "topup",
    path: "",
    component: () => import("/opt/app/src/pages/topup/index.vue")
  },
  {
    name: "topup-qr",
    path: "qr",
    component: () => import("/opt/app/src/pages/topup/qr.vue")
  }
]
  },
  {
    name: transfer2ERQimqR8EMeta?.name,
    path: "/transfer",
    component: () => import("/opt/app/src/pages/transfer.vue"),
    children: [
  {
    name: "transfer-address",
    path: "address",
    component: () => import("/opt/app/src/pages/transfer/address.vue")
  },
  {
    name: "transfer-check",
    path: "check",
    component: () => import("/opt/app/src/pages/transfer/check.vue")
  },
  {
    name: "transfer",
    path: "",
    component: () => import("/opt/app/src/pages/transfer/index.vue")
  },
  {
    name: "transfer-scan",
    path: "scan",
    component: () => import("/opt/app/src/pages/transfer/scan.vue")
  },
  {
    name: "transfer-status",
    path: "status",
    component: () => import("/opt/app/src/pages/transfer/status.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomev5s5EwULrqMeta || {},
    component: () => import("/opt/app/src/pages/welcome.vue")
  }
]