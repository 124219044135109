import { useUserEndpoints } from '../routes/users.routes';

/**
 * Получить данные текущего пользователя
 */
export function useApiUserGetInfo<T = ModelUserInfoResponse>(options?: UseApiOptions<T>) {
  return useWrappedFetch<T>(useUserEndpoints().me(), {
    ...options,
  });
}

/**
 * Получить данные с адресами текущего пользователя
 */
export function useApiUserGetAddresses<T = ModelUserAddressesResponse>(options?: UseApiOptions<T>) {
  return useApi<T>(useUserEndpoints().addresses(), {
    ...options,
    model: modelUserAddressesResponse,
  });
}

/**
 * Установить реферальный код
 */
export function useApiUserSetReferralCode<T = ModelSetReferralCodeResponse>(
  referralCode: string,
  options?: UseApiOptions<T>,
) {
  return useWrappedFetch<T>(useUserEndpoints().setReferralCode(), {
    ...options,
    method: 'post',
    body: {
      referralCode,
    },
  });
}

/**
 * Получить информацию о рефералах
 */
export function useApiUserGetReferrals<T = ModelUserReferralsResponse>(options?: UseApiOptions<T>) {
  return useWrappedFetch<T>(useUserEndpoints().referrals(), {
    ...options,
    model: modelUserReferralsResponse,
  });
}
