/**
 * Плагин для редиректа страниц при запуске вне мобильного устройства
 */
export default defineNuxtPlugin(() => {
  const router = useRouter();
  const { $pwa } = useNuxtApp();
  const { public: publicConfig } = useRuntimeConfig();
  const { telegramApp } = publicConfig;
  router.beforeEach((to, _from, next) => {
    if (hasDebug()) {
      next();
      return;
    }
    if (!telegramApp && !to.meta.noPWA && (!isMobile() || !$pwa?.isPWAInstalled)) {
      next({ name: 'to-install' });
      return;
    }
    next();
  });
});
