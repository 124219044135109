import { useAccountsEndpoints } from '../routes/accounts.routes';

/**
 * Получить данные счетов текущего пользователя
 */
export function useApiAccountsInfo<T = ModelInnerUserAllAccountsResponse>(
  options?: UseApiOptions<T>,
) {
  return useWrappedFetch<T>(useAccountsEndpoints().all(), {
    ...options,
    model: modelInnerUserAllAccountsResponse,
  });
}
