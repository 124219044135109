/**
 * Счетчик кликов для совершения действия
 *
 * @param toggleCount - количество кликов для совершения действия
 * @param callback - функция для вызова после достижения количества кликов
 */
export function useClickCounter(toggleCount: number, callback: () => void) {
  const count = ref(0);

  const click = () => {
    count.value++;
    if (count.value === toggleCount) {
      count.value = 0;
      callback();
    }
  };

  return { count, click };
}

/**
 * Определение платформы запуска приложения
 */
export function usePlatform() {
  /**
   * Флаг, указывающий, что платформа — Telegram Mini App
   */
  const isTelegramMiniApp = computed(() => isTMA());

  return { isTelegramMiniApp };
}

