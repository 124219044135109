import { toastRef } from '~/utils/toast.utils';

import type { Dayjs } from 'dayjs';

interface AuthState {
  loginToken: string;
  botName: string;
  expiredAt: number;
  isLoginAttempt: boolean;
  lastRefresh: Dayjs | null;
  jwt: {
    accessToken: string;
    expiredAt: number;
    refreshToken: string;
  };
}

/**
 * Управление авторизацией пользователя
 */
export const useAuth = defineStore(
  'auth',
  () => {
    const userStore = useUserStore();

    const state = ref<AuthState>({
      loginToken: '',
      botName: '',
      expiredAt: 0,
      isLoginAttempt: false,
      lastRefresh: null,
      jwt: {
        accessToken: '',
        expiredAt: 0,
        refreshToken: '',
      },
    });

    /**
     * Происходит ли сейчас обновление авторизации
     */
    let isRefreshing = false;

    let refreshSubscribers: Array<(token: string) => void> = [];

    // Function to notify all subscribers once the token is refreshed
    function notifySubscribers(token: string) {
      refreshSubscribers.forEach((callback) => callback(token));
      refreshSubscribers = [];
    }

    /**
     * Сохранить accessToken и expiredAt в куки для работы на SSR
     */
    function persistToCookie() {
      const { accessToken, expiredAt } = state.value.jwt;
      setCookie('accessToken', accessToken);
      setCookie('expiredAt', expiredAt.toString());
    }

    /**
     * Очищает токен от префикса login_
     *
     * @param token
     */
    function _clearStringFromPrefix(srt: string, prefix: string = 'login_') {
      if (srt.startsWith(prefix)) {
        return srt.slice(prefix.length);
      }
      return srt;
    }

    /**
     * Сброс состояния на исходное
     */
    function _resetState() {
      _clearLoginToken();
      state.value.botName = '';
      state.value.jwt.accessToken = '';
      state.value.jwt.expiredAt = 0;
      state.value.jwt.refreshToken = '';
      state.value.isLoginAttempt = false;
      state.value.lastRefresh = null;
      persistToCookie();
    }

    /**
     * Очищаем loginToken, чтобы в дальнейшем его можно было перезапросить
     */
    function _clearLoginToken() {
      state.value.loginToken = '';
      state.value.expiredAt = 0;
    }

    /**
     * Выход из приложения
     */
    async function logout() {
      _resetState();
      const { resetState: resetAccounts } = useAccountsStore();
      const { resetState: resetTransactions } = useTransactionsStore();
      const { logout: logoutUser } = useUserStore();
      resetAccounts();
      resetTransactions();
      logoutUser();
      await navigateTo(DEFAULT_AUTH);
    }

    /**
     * Получить loginToken, если нет активного в storage
     */
    async function fetchLoginTokenIfRequired() {
      if (dateTime.isInFuture(state.value.expiredAt)) return;
      const response = await useApiAuthPrepare();
      if (response.status !== 'ok') {
        throw new Error('Error in fetching loginToken');
      }
      const { data } = response;
      if (!data?.loginToken) {
        throw new Error('Error in fetching loginToken');
      }
      state.value.loginToken = data.loginToken;
      state.value.botName = data.botName;
      state.value.expiredAt = data.expiredAt;
    }

    /**
     * Попытка авторизоваться по loginToken
     */
    async function tryToLoginByToken() {
      if (!state.value.loginToken) {
        throw new Error('Undefined loginToken');
      }
      const rawToken = _clearStringFromPrefix(state.value.loginToken);
      try {
        const response = await useApiAuthByLoginToken(rawToken, {
          async onResponseError(ctx) {
            if (ctx.response.status === HTTP_RESPONSE_CODE.NOT_ACCEPTABLE) {
              // Токен уже был использован - надо стереть и получить заново
              _clearLoginToken();
              await fetchLoginTokenIfRequired();
              if (toastRef.value && state.value.isLoginAttempt) {
                toastRef.value.add({
                  severity: 'error',
                  summary: 'Что-то пошло не так',
                  detail: 'Пожалуйста, подтвердите заново вход через телеграм',
                  life: 5000,
                });
              }
            }
            return false;
          },
        });
        const { data: jwt } = response;
        // Если токен не подтверждён в ТГ, то авторизация невозможна
        if (!jwt) return;
        // error._object[error._key].statusCode
        _clearLoginToken();
        // Если произошла какая-то ошибка и бэк не ответил
        if (jwt.status || jwt === null) {
          throw new Error('Undefined jwt data');
        }
        state.value.jwt.accessToken = jwt.accessToken;
        state.value.jwt.expiredAt = jwt.expiredAt;
        state.value.jwt.refreshToken = jwt.refreshToken;
        state.value.isLoginAttempt = false;
        state.value.lastRefresh = dateTime.today();
        persistToCookie();
        userStore.fetchUser();
      } catch (e) {
        logDevMessage(e);
      }
    }

    /**
     * authToken пора рефрешить
     */
    const isRequiredToRefresh = computed(() => !dateTime.isInFuture(state.value.jwt.expiredAt));

    // TODO: отладить работу апдейта токенов
    async function refreshToken(): Promise<string> {
      if (!state.value.jwt.refreshToken) throw new Error('Refresh token absent');
      if (isRefreshing) {
        return new Promise((resolve) => {
          refreshSubscribers.push(resolve);
        });
      }
      if (import.meta.server) return '';
      isRefreshing = true;
      try {
        const response = await useApiAuthRefresh(state.value.jwt.refreshToken);
        if (response.status !== 'ok') {
          throw new Error('Failed refresh token request');
        }
        const { data: jwt } = response;
        if (!jwt && (!jwt?.accessToken || !jwt?.expiredAt || !jwt?.refreshToken)) {
          throw new Error('Undefined jwt data after refreshing token');
        }
        state.value.jwt.accessToken = jwt.accessToken;
        state.value.jwt.expiredAt = jwt.expiredAt;
        state.value.jwt.refreshToken = jwt.refreshToken;
        state.value.lastRefresh = dateTime.today();
        persistToCookie();
        notifySubscribers(jwt.accessToken);
        return state.value.jwt.accessToken;
      } catch (error) {
        await logout();
        console.error(error);
        throw error;
      } finally {
        isRefreshing = false;
      }
    }

    /**
     * Залогинен ли пользователь
     */
    const isLoggedIn = computed(() => !!state.value.jwt.accessToken);

    /**
     * Проверка не истёк ли токен
     */
    const isLoginTokenAlive = computed(() => dateTime.isInFuture(state.value.expiredAt));

    /**
     * Авторизация через loginToken
     *
     * Получение loginToken, если это гость и первый заход на страницу
     * Авторизация по loginToken, если это вернувшийся пользователь
     */
    async function optimisticAuth() {
      infoDevMessage('auth.optimisticAuth');
      if (isLoggedIn.value) return true;
      if (!isLoginTokenAlive.value) {
        await fetchLoginTokenIfRequired();
      } else {
        await tryToLoginByToken();
        // TODO: если прошло много времени - показать уведомление, что нужно повторное подтверждение
        if (!state.value.loginToken) {
          return false;
        }
        return true;
      }
      return false;
    }

    async function optimisticAuthIfNotYet() {
      infoDevMessage('auth.optimisticAuthIfNotYet');
      if (isLoggedIn.value) {
        const now = dateTime.today();
        if (now.diff(state.value.lastRefresh, 'days') > 0) {
          await refreshToken();
        }
        return;
      }
      if (state.value.loginToken && isLoginTokenAlive.value) {
        await tryToLoginByToken();
      }
    }

    function onLoginAttempt() {
      state.value.isLoginAttempt = true;
      // TODO: Сохранять время совершения попытки
    }

    return {
      state,

      isLoggedIn,

      /**
       * Токен для логина
       */
      loginToken: computed(() => state.value.loginToken),
      /**
       * Токен для доступа
       */
      accessToken: computed(() => state.value.jwt.accessToken),
      /**
       * Имя бота для авторизации
       */
      botName: computed(() => state.value.botName),
      isLoginTokenAlive,

      fetchLoginTokenIfRequired,
      tryToLoginByToken,
      refreshToken,
      logout,
      optimisticAuth,
      optimisticAuthIfNotYet,
      onLoginAttempt,
      isRequiredToRefresh,
    };
  },
  {
    persist: true,
  },
);
