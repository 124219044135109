import { z } from 'zod';
import { AVAILABLE_NETWORKS } from './coins.model';

/**
 * Модель ответа на auth prepare
 */
export const modelAuthPrepareResponse = z.object({
  data: z.object({
    loginToken: z.string(),
    expiredAt: z.number(),
    botName: z.string(),
  }),
});

/**
 * Тип модели ответа на auth prepare
 */
export type ModelAuthPrepareResponse = z.infer<typeof modelAuthPrepareResponse>;

/**
 * Модель с информацией ответа авторизации
 */
export const modelAuthResponse = z.union([
  z.object({
    status: z.string(),
    data: z.object({
      /**
       * Токен доступа
       */
      accessToken: z.string(),
      /**
       * Время протухания access_token
       */
      expiredAt: z.number(),
      /**
       * Токен обновления токена доступа
       */
      refreshToken: z.string(),
    }),
  }),
  z.object({
    status: z.number(),
  }),
]);

/**
 * Тип модели с информацией ответа авторизации
 */
export type ModelAuthResponse = z.infer<typeof modelAuthResponse>;

const userSettings = z.object({
  /**
   * Включены ли пуши
   */
  isPushOn: z.boolean(),
});

export const modelInnerUserInfoResponse = z.object({
  /**
   * Логин пользователя
   */
  login: z.string(),
  /**
   * Аватар пользователя
   */
  userImg: z.string(),
  /**
   * Реферальный код пользователя
   */
  referralKey: z.string(),
  /**
   * Задан ли реферальный код приглашающего
   */
  hasReferrer: z.boolean(),
  /**
   * Настройки пользователя
   */
  settings: userSettings,
});

export const modelUserInfoResponse = z.object({
  status: z.string(),
  data: modelInnerUserInfoResponse,
});

/**
 * Модель с информацией о пользователе
 */
export type ModelUserInfoResponse = z.infer<typeof modelUserInfoResponse>;
export type ModelInnerUserInfoResponse = z.infer<typeof modelInnerUserInfoResponse>;

/**
 * Модель адреса пользователя в сети
 */
export const modelUserAddress = z.object({
  /**
   * Сеть
   */
  network: z.enum(AVAILABLE_NETWORKS),
  /**
   * Адрес кошелька
   * TODO: добавить валидацию адресов
   */
  address: z.string(),
});

/**
 * Модель всех адресов пользователя
 */
export const modelUserAddressesResponse = z.array(modelUserAddress);

/**
 * Тип с информацией обо всех адресах пользователя в сетях
 */
export type ModelUserAddressesResponse = z.infer<typeof modelUserAddressesResponse>;

/**
 * Модель ответа на установку реферального кода
 */
export const modelSetReferralCodeResponse = z.object({
  data: z
    .object({
      referralRegistered: z.object({
        referralCode: z.string(),
      }),
    })
    .nullable(),
  errors: z
    .object({
      // ['YOU_ALREADY_HAVE_A_REFERRER', "YOU_CAN'T_BE_A_REFERRAL_FOR_YOURSELF", 'THE_REFERRAL_CODE_BELONGS_TO_NO_ONE']
      base: z.array(z.string()),
    })
    .nullable(),
});

/**
 * Тип модели ответа на установку реферального кода
 */
export type ModelSetReferralCodeResponse = z.infer<typeof modelSetReferralCodeResponse>;

/**
 * Модель ответа на информацию о рефералах
 */
export const modelUserReferralsResponse = z.array(
  z.object({
    totalReferrals: z.number(),
    activeReferrals: z.number(),
  }),
);

/**
 * Тип модели ответа на информацию о рефералах
 */
export type ModelUserReferralsResponse = z.infer<typeof modelUserReferralsResponse>;
