import { z } from 'zod';

/**
 * Общая мета информация
 */
export const modelCommonMetaResponse = z.object({
  currentPage: z.number(),
  nextPage: z.number(),
  totalPages: z.number(),
  totalEntries: z.number(),
});
