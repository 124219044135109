// импорты для тестов
import { API_DEFAULT_VERSION } from '../api.const';
import { versioned } from '~/utils/api.utils';

/**
 * Маршруты для запросов данных транзакций пользователя
 */
export const useTransactionsEndpoints = (version?: number) => {
  const withVersion = version && Number.isInteger(version) ? version : API_DEFAULT_VERSION;

  return {
    /**
     * Информация обо всех транзакциях
     *
     * @method GET
     */
    all: () => versioned(`/transactions`, withVersion),

    /**
     * Информация об одной транзакции
     *
     * @method GET
     */
    get: (txIdOrHash: string) => versioned(`/transactions/${txIdOrHash}`, withVersion),
  };
};
