import validate from "/opt/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/app/src/middleware/auth.ts"),
  "public-only": () => import("/opt/app/src/middleware/publicOnly.ts"),
  referral: () => import("/opt/app/src/middleware/referral.ts")
}