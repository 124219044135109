/**
 * Middleware для проверки наличия реферального ключа
 */
export default defineNuxtRouteMiddleware(async (to) => {
  logDevMessage('referral middleware');
  if (import.meta.server) return;
  const { $pinia } = useNuxtApp();
  const userStore = useUserStore($pinia);
  const { user } = storeToRefs(userStore);
  if (to.meta.isPublic) return;
  if (!user.value.isUserLoaded) {
    await userStore.fetchUser();
  }
  if (!user.value.hasReferrer) {
    logDevMessage('referral middleware: no referrer');
    await navigateTo(DEFAULT_LOCK_KEY, { external: true });
  }
});
