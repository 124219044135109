import z from 'zod';

// const modelTransactionType = z.union(['paySBP', '']);

export const modelPaySBPTransaction = z.object({
  type: z.string('paySBP'),
  createdAt: z.number(),
  txHash: z.string(),
  status: z.string(),
  value: z.string(),
  coin: z.string(),
  fiatValue: z.string(),
});

// TODO: распилить на составляющие
export const modelUniversalTransaction = z.object({
  type: z.string(),
  createdAt: z.number(),
  status: z.string(),
  txHash: z.string().optional(),
  txId: z.string().optional(),
  value: z.string(),
  fiatValue: z.string().optional(),
  coin: z.string(),
  to: z.any(),
  from: z
    .object({
      address: z.string(),
      network: z.string(),
    })
    .optional(),
});

export const modelTransaction = z.union([
  //
  modelPaySBPTransaction,
  modelUniversalTransaction,
]);

export const modelInnerTransactionsResponse = z.array(modelTransaction);

export const modelTransactionsResponse = z.object({
  data: modelInnerTransactionsResponse,
  meta: modelCommonMetaResponse,
});
export type ModelTransactionsResponse = z.infer<typeof modelTransactionsResponse>;
export type ModelInnerTransactionsResponse = z.infer<typeof modelInnerTransactionsResponse>;

export const modelInnerTransaction = modelTransaction;
export const modelTransactionResponse = z.object({
  data: modelInnerTransaction,
});
