<script setup lang="ts">
import IconInfo from '@/assets/svg/16/info.svg';

interface Props {
  isIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isIcon: false,
});

const { isIcon } = toRefs(props);

const emit = defineEmits(['click']);

const handleClick = () => {
  emit('click');
};
</script>

<template>
  <button class="a-beta-button" :class="{ '-icon': isIcon }" @click="handleClick">
    beta
    <IconInfo v-if="isIcon" />
  </button>
</template>

<style lang="scss">
.a-beta-button {
  height: 24px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: #f4f5f6;
  color: $text-primary;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.5px;

  &.-icon {
    background: rgba(255, 255, 255, 0.1);
    color: white;

    svg {
      width: 14px;
    }
  }
}
</style>
