/**
 * Определяет запущено ли приложение на мобильном устройстве или нет
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/**
 * Определяет является ли это Telegram Mini App
 */
export const isTMA = () => useRuntimeConfig().public.telegramApp;
