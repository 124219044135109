<script setup lang="ts">
import { toastRef } from '~/utils/toast.utils';

import successIcon from '@/assets/svg/16/toast-success.svg';
import errorIcon from '@/assets/svg/16/toast-error.svg';
import warnIcon from '@/assets/svg/16/toast-warn.svg';
import infoIcon from '@/assets/svg/16/toast-info.svg';

const toast = useToast();

const { isOnline } = useNetwork();

const getIconClass = (severity: string) => {
  switch (severity) {
    case 'success':
      return successIcon;
    case 'error':
      return errorIcon;
    case 'info':
      return infoIcon;
    case 'warn':
      return warnIcon;
    default:
      return warnIcon;
  }
};

watch(isOnline, (online) => {
  toast.removeAllGroups();

  if (!online) {
    toast.add({
      severity: 'error',
      summary: 'Потеряно соединение с интернетом',
    });
  } else {
    toast.add({
      severity: 'success',
      summary: 'Соединение восстановлено',
      life: 5000,
    });
  }
});
</script>
<template>
  <Toast ref="toastRef" position="top-center" unstyled class="m-toast">
    <template #message="{ message }">
      <div class="m-toast-row">
        <component :is="getIconClass(message.severity)" class="m-toast-icon" />
        <div>
          <h4 v-if="message.summary" class="text-bold text-tiny">
            {{ message.summary }}
          </h4>
          <p v-if="message.detail">{{ message.detail }}</p>
        </div>
      </div>
    </template>
  </Toast>
</template>

<style lang="scss">
.m-toast {
  width: 270px;
  margin: 0 auto;
  text-align: center;
  transform: translate(-50%, 0);
  padding-top: env(safe-area-inset-top);

  .m-toast-row {
    display: inline-flex;
    text-align: left;
    padding: 10px 12px;
    gap: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    background: #fff;
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 1;
    box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.15);
  }
  p {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.15px;
  }

  button {
    display: none;
  }

  .p-toast-message-leave-active {
    opacity: 0;
    transform: translateY(-20px);
    transition:
      opacity 0.45s ease,
      transform 0.45s ease;
  }
}
</style>
