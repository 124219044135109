// импорты для тестов
import { API_DEFAULT_VERSION } from '../api.const';
import { versioned } from '~/utils/api.utils';

/**
 * Маршруты для запросов данных о счетах пользователя
 */
export const useAccountsEndpoints = (version?: number) => {
  const withVersion = version && Number.isInteger(version) ? version : API_DEFAULT_VERSION;

  return {
    /**
     * Информация обо всех счетах пользователя
     */
    all: () => versioned(`/accounts/all`, withVersion),
  };
};
