/**
 * Тип данных стора со всеми счетами
 */
interface AccountsStore {
  totalFiat: string;
  items: ModelUserAccount[];
}

/**
 * Стор пользовательских счетов
 */
export const useAccountsStore = defineStore(
  'accounts',
  () => {
    const state = ref<AccountsStore>({
      totalFiat: '',
      items: [],
    });

    /**
     * Сброс состояния на исходное
     */
    function resetState() {
      state.value.totalFiat = '';
      state.value.items = [];
    }

    /**
     * Получить аккаунты пользователя
     */
    async function fetchAccounts() {
      const accountsData = await useApiAccountsInfo();
      if (accountsData) {
        setAccounts(accountsData);
      }
    }

    function setAccounts(newData: ModelInnerUserAllAccountsResponse) {
      state.value.items = newData.items.map((item) => ({
        ...item,
        balanceCoin: `${item.balance} ${item.coin}`,
      }));
      state.value.totalFiat = newData.totalFiat;
    }

    /**
     * У пользователя нет денег на всех аккаунтах
     */
    const hasZeroBalance = computed(
      () => state.value.totalFiat === '' || state.value.totalFiat === '0.0',
    );

    /**
     * У пользователя нет денег на конкретной монете
     *
     * @param coin
     */
    function getBalanceIsZeroFor(coin: CoinType) {
      const account = state.value.items.find((item) => item.coin === coin);
      return account?.balanceFiat === '0.0';
    }

    return {
      state,
      hasZeroBalance,

      fetchAccounts,
      getBalanceIsZeroFor,
      resetState,
    };
  },
  {
    persist: true,
  },
);
