<script setup lang="ts">
import ABaseButton from './ABaseButton/ABaseButton.vue';
import arrowLeft from '@/assets/svg/24/arrow-left.svg';
import logoIcon from '@/assets/svg/logo/full.svg';

import { ViewBaseButton, TypeBaseButton } from '~/components/atoms/ABaseButton';

interface Props {
  back?: boolean;
  goTo?: string;
  header?: string;
  headerIcon?: string;
  headerLogo?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  back: true,
  goTo: undefined,
  header: '',
  headerLogo: false,
  headerIcon: '',
});
const { back, header, goTo, headerIcon, headerLogo } = toRefs(props);

const router = useRouter();

const handleClick = () => {
  if (goTo.value) {
    navigateTo(goTo.value);
  } else if (window.history.length > 1) {
    router.back();
  } else {
    navigateTo(DEFAULT_ROOT);
  }
};
</script>

<template>
  <header class="a-header">
    <div class="container">
      <div class="a-header-row">
        <ABaseButton
          v-if="back"
          :view="ViewBaseButton.Simple"
          :type="TypeBaseButton.Simple"
          class="back-button"
          @click="handleClick"
        >
          <component :is="arrowLeft" />
        </ABaseButton>
        <div class="a-header-body" :class="{ '-back': back }">
          <div v-if="headerLogo" class="a-header-logo">
            <component :is="logoIcon" class="logo-icon" />
            <ABetaButton />
          </div>
          <img v-if="headerIcon" :src="headerIcon" :alt="header" class="icon" />
          <span>{{ header }}</span>
        </div>
      </div>
    </div>
  </header>
</template>
<style lang="scss">
.a-header {
  position: relative;
  z-index: 1;
  padding: 20px 0;
  .back-button {
    padding: 12px;
    margin: -12px 0;
    margin-left: -12px;
    border-radius: 50%;
    color: $text-secondary;
  }
}
.a-header-logo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.a-header-row {
  display: flex;
  align-items: center;
}
.a-header-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 600;

  &.-back {
    padding-right: 36px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .logo-icon {
    height: 32px;
  }
}
</style>
