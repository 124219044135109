import type { TransactionItem } from '~/types/crypto.types';

interface State {
  transactions: TransactionItem[];
}

export const useTransactionsStore = defineStore(
  'transactions',
  () => {
    const state = ref<State>({
      transactions: [],
    });

    /**
     * Сброс состояния на исходное
     */
    function resetState() {
      state.value.transactions = [];
    }

    const { data: transactionsData, execute: fetchTransactionsData } = useApiTransactions();

    async function fetchTransactions() {
      await fetchTransactionsData();
      if (!transactionsData.value) {
        return;
      }
      setTransactions(transactionsData.value as any as TransactionItem[]);
    }

    function setTransactions(list: MaybeRef<TransactionItem[]>) {
      state.value.transactions = unref(list);
    }

    return {
      state,

      fetchTransactions,
      resetState,
    };
  },
  {
    persist: true,
  },
);
