/**
 * User store interface
 */
interface UserState {
  /**
   * Флаг загрузки данных пользователя
   */
  isUserLoaded: boolean;
  /**
   * Логин пользователя
   */
  login: string;
  /**
   * Ключ приглашения для других пользователей
   */
  referralKey: string;
  /**
   * Задан ли приглашающий реферал
   */
  hasReferrer: boolean;
  /**
   * Изображение пользователя
   */
  userImg: string;
  /**
   * Общее количество рефералов
   */
  referrals: {
    total: number;
    active: number;
  };
  /**
   * Настройки пользователя
   */
  settings: {
    isPushOn: boolean;
  };
  /**
   * Адреса пользователя
   */
  addresses: ModelUserAddressesResponse;
}

/**
 * Хранилище данных пользователя
 */
export const useUserStore = defineStore(
  'userStore',
  () => {
    const authStore = useAuth();

    const state = ref<UserState>({
      isUserLoaded: false,
      login: '',
      referralKey: '',
      hasReferrer: false,
      userImg: '',
      settings: {
        isPushOn: false,
      },
      referrals: {
        total: 0,
        active: 0,
      },
      addresses: [],
    });

    /**
     * Сбросить состояние пользователя
     */
    function _resetState() {
      state.value.isUserLoaded = false;
      state.value.login = '';
      state.value.referralKey = '';
      state.value.hasReferrer = false;
      state.value.userImg = '';
      state.value.settings.isPushOn = false;
      state.value.addresses = [];
      state.value.referrals = {
        total: 0,
        active: 0,
      };
    }

    function logout() {
      _resetState();
    }

    function setUser(user?: ModelInnerUserInfoResponse) {
      // TODO: Check this functionality
      if (!user) {
        _resetState();
        return;
      }
      const imgValue = computed(() => user.userImg || `/images/avatar.png`); // https://robohash.org/${user.login}`

      state.value.login = user.login;
      state.value.referralKey = user.referralKey;
      state.value.hasReferrer = user.hasReferrer;
      state.value.userImg = imgValue.value;
      state.value.settings.isPushOn = user.settings.isPushOn;
      state.value.isUserLoaded = true;
    }

    /**
     * Получить данные пользователя
     */
    async function fetchUser() {
      if (!authStore.isLoggedIn) {
        return;
      }
      const response = await useApiUserGetInfo();
      if (response.status === 'ok') {
        setUser(response.data);
      }
    }
    fetchUser();

    async function fetchReferrals() {
      if (!authStore.isLoggedIn) {
        return;
      }
      const response = await useApiUserGetReferrals();
      const data = response[0];

      if (!data) {
        console.error('Error fetching user referrals');
        return;
      }

      state.value.referrals = {
        total: Number(data.totalReferrals),
        active: Number(data.activeReferrals),
      };
    }
    fetchReferrals();

    const {
      data: userAddressesData,
      error: addressError,
      execute: fetchUserAddressesData,
    } = useApiUserGetAddresses({ immediate: false });

    function setUserAddresses(addresses?: ModelUserAddressesResponse) {
      if (!addresses) {
        // очищаем массив адресов, если нет данных
        state.value.addresses.splice(0, state.value.addresses.length);
        return;
      }
      // Устанавливаем массив объектов
      state.value.addresses = state.value.addresses.concat(addresses);
    }

    /**
     * Получить адреса пользователя
     */
    async function fetchUserAddresses() {
      await fetchUserAddressesData();
      const data = userAddressesData.value;

      if (!data) {
        console.error('Error fetching user addresses', addressError.value);
        return;
      }

      setUserAddresses(data);
    }

    /**
     * Ключ приглашающего был установлен
     * Чуть экономим на запросах в бэк
     */
    function persistHasReferralKey() {
      state.value.hasReferrer = true;
    }

    return {
      state,
      logout,
      fetchUser,
      fetchUserAddresses,
      persistHasReferralKey,
      fetchReferrals,
      /**
       * Данные пользователя
       */
      user: computed(() => ({
        isUserLoaded: state.value.isUserLoaded,
        /**
         * Изображение пользователя
         * @deprecated Не используется
         */
        image: state.value.userImg,
        /**
         * Логин пользователя
         */
        login: state.value.login,
        /**
         * Пригласительный ключ самого пользователя
         */
        referralKey: state.value.referralKey,
        /**
         * Был определён пригласительный ключ
         */
        hasReferrer: state.value.hasReferrer || false,
      })),
      addresses: computed(() => state.value.addresses),
      referrals: computed(() => state.value.referrals),
    };
  },
  {
    persist: true,
  },
);
